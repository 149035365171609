<template>
  <div>
    <!-- Dashboard 3 Section -->
    <section id="dashboard3Tab" class="tabcontent">
      <div class="update_notice_area" v-if="notification.message">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g
            id="Group_990"
            data-name="Group 990"
            transform="translate(-115 -205)"
          >
            <rect
              id="Rectangle_1097"
              data-name="Rectangle 1097"
              width="20"
              height="20"
              rx="3"
              transform="translate(115 205)"
              fill="#fd3e5b"
            />
            <g id="warning" transform="translate(118.65 209.5)">
              <path
                id="Icon_color"
                data-name="Icon color"
                d="M12.181,10.957H.506a.421.421,0,0,1-.369-.219L.056,10.6a.454.454,0,0,1,0-.438L5.888.213A.441.441,0,0,1,6.263,0h.173a.441.441,0,0,1,.375.213l5.82,9.953a.451.451,0,0,1,0,.438l-.08.133A.421.421,0,0,1,12.181,10.957ZM6.055,7.5a.289.289,0,0,0-.289.289v.577a.289.289,0,0,0,.289.289h.577a.289.289,0,0,0,.289-.289V7.785A.289.289,0,0,0,6.632,7.5Zm-.1-3.46a.288.288,0,0,0-.288.323l.23,1.857a.144.144,0,0,0,.143.127h.612a.145.145,0,0,0,.144-.127L7.024,4.36a.289.289,0,0,0-.287-.323H5.951Z"
                transform="translate(0)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>

        <p><span>TODO:</span> {{ notification.message }}</p>
      </div>
      <div class="profile_wrapper" v-if="user.profile">
        <div class="profile_form">
          <div class="profile_header">
            <p>Profiles</p>
            <h4>Create or select an existing profile.</h4>
          </div>
          <div class="profile_select_col">
            <v-select
              :clearSearchOnSelect="false"
              v-model="store.state.selectedProfileOption"
              :options="
                store.state.user.profiles.map((profile) => {
                  return { label: profile.name, id: profile.id };
                }).concat([{ label: 'Create Profile', id: 'Create Profile' }])
              "
            >
            </v-select>
            <form class="profile_select_form" v-if="!user.profile">
              <label for=""> Profile Name </label>
              <input type="text" v-model="user.profile.name" />
            </form>
          </div>

          <div class="profile_details_area">
            <p>PROFILE DETAILS</p>
          </div>

          <form
            class="profile_input_form"
            v-if="user.profile && user.profile.personalDetails"
          >
            <div class="form_row">
              <div class="input_box1">
                <label> First Name </label>
                <input
                  type="text"
                  v-model="user.profile.personalDetails.FirstName"
                  @blur="capitalize"
                />
              </div>
              <div class="input_box1 input_box2">
                <label> Last Name </label>
                <input
                  type="text"
                  v-model="user.profile.personalDetails.LastName"
                  @blur="capitalize"
                />
              </div>
            </div>

            <div class="form_row">
              <div class="input_box1">
                <label> Cell Phone </label>
                <input
                  type="number"
                  v-model="user.profile.personalDetails.CellPhone"
                />
              </div>
              <div class="input_box1 input_box2">
                <label> Email </label>
                <input
                  type="email"
                  v-model="user.profile.personalDetails.Email"
                />
              </div>
            </div>

            <div class="form_row">
              <div class="input_box1">
                <label> Social Security Number (at least Last Four) </label>
                <input
                  type="number"
                  v-model="user.profile.personalDetails.SocialSecurityNumber"
                />
              </div>
              <div class="input_box1 input_box2">
                <label> Date of birth </label>
                <div class="input_date_area">
                  <div style="width: 100%">
                    <v-select
                      :clearSearchOnSelect="false"
                      v-model="user.profile.personalDetails.DOBMonth"
                      :options="store.state.months"
                    >
                    </v-select>
                  </div>
                  <input
                    type="number"
                    v-model="user.profile.personalDetails.DOBDay"
                    placeholder="Day (01)"
                  />
                  <input
                    type="number"
                    v-model="user.profile.personalDetails.DOBYear"
                    placeholder="Year (1999)"
                  />
                </div>
              </div>
            </div>

            <div class="form_row">
              <div class="input_box1">
                <label> Address (Street and number) </label>
                <input
                  type="text"
                  v-model="user.profile.personalDetails.Address"
                  @blur="capitalize"
                />
              </div>
              <div class="input_box1 input_box2">
                <label> City </label>
                <input
                  type="text"
                  v-model="user.profile.personalDetails.City"
                  @blur="capitalize"
                />
              </div>
            </div>
            <div class="form_row">
              <div class="input_box1">
                <label> State </label>

                <div style="width: 100%">
                  <v-select
                    :clearSearchOnSelect="false"
                    id="state"
                    name="state"
                    autocomplete="state"
                    v-model="user.profile.personalDetails.State"
                    @blur="capitalize"
                    :options="store.state.states"
                  >
                  </v-select>
                </div>
                <input
                  id="statei"
                  name="state"
                  autocomplete="state"
                  v-model="user.profile.personalDetails.State"
                  @blur="capitalize"
                  style="display: none"
                  disabled="disabled"
                  onblur="if(this.value==''){app.toggleField(this,document.getElementById('state'));}"
                />
              </div>
              <div class="input_box1 input_box2">
                <label> ZIP Code </label>
                <input
                  type="number"
                  v-model="user.profile.personalDetails.ZIPCode"
                  @blur="capitalize"
                />
              </div>
            </div>

            <div class="profile_details_area details_proof">
              <svg
                id="file_text_outlined"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <path
                  id="Icon_color"
                  data-name="Icon color"
                  d="M5.833,8.333h-5A.834.834,0,0,1,0,7.5V3.016a1.257,1.257,0,0,1,.367-.883L2.133.367A1.257,1.257,0,0,1,3.016,0H5.833a.834.834,0,0,1,.833.833V7.5A.834.834,0,0,1,5.833,8.333Zm-5-5V7.5h5V.833h-2.5V2.917a.417.417,0,0,1-.417.417ZM3.542,6.667H1.875a.209.209,0,0,1-.208-.209V6.042a.209.209,0,0,1,.208-.208H3.542a.209.209,0,0,1,.208.208v.417A.209.209,0,0,1,3.542,6.667ZM4.792,5H1.875a.209.209,0,0,1-.208-.208V4.375a.209.209,0,0,1,.208-.208H4.792A.209.209,0,0,1,5,4.375v.417A.208.208,0,0,1,4.792,5Z"
                  transform="translate(1.667 0.833)"
                />
              </svg>

              <p>PROOF OF DOCUMENTS</p>
            </div>

            <div class="file_upload_area">
              <div class="upload_items">
                <div class="upload_header">
                  <p>Social Security Number</p>
                  <svg
                    id="error_outlined"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M5.833,11.667a5.834,5.834,0,1,1,5.833-5.833A5.84,5.84,0,0,1,5.833,11.667Zm0-10.5A4.667,4.667,0,1,0,10.5,5.833,4.672,4.672,0,0,0,5.833,1.167Zm.292,7H5.542a.292.292,0,0,1-.292-.292V7.292A.292.292,0,0,1,5.542,7h.583a.292.292,0,0,1,.291.292v.583A.292.292,0,0,1,6.125,8.167Zm-.6-2.334a.146.146,0,0,1-.145-.128L5.145,3.827a.291.291,0,0,1,.29-.327h.8a.291.291,0,0,1,.291.327L6.288,5.705a.146.146,0,0,1-.145.128H5.523Z"
                      transform="translate(1.167 1.167)"
                      fill="#8d949f"
                    />
                  </svg>
                </div>
                <div class="upload_body text-center">
                  <label for="file_upload">
                    <svg
                      id="photo_gallery_outlined"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <path
                        id="Icon_color"
                        data-name="Icon color"
                        d="M15,18.333H5a1.669,1.669,0,0,1-1.667-1.666H15A1.668,1.668,0,0,0,16.667,15V3.333A1.669,1.669,0,0,1,18.333,5V15A3.337,3.337,0,0,1,15,18.333ZM13.333,15H1.666A1.668,1.668,0,0,1,0,13.333V1.666A1.668,1.668,0,0,1,1.666,0H13.333A1.668,1.668,0,0,1,15,1.666V13.333A1.668,1.668,0,0,1,13.333,15ZM2.083,11.941h.009a.4.4,0,0,0,.324-.167l2.15-2.858A.425.425,0,0,1,4.9,8.75a.449.449,0,0,1,.342.167l.833,1.133a.425.425,0,0,0,.333.167h.007a.4.4,0,0,0,.318-.158L8.609,7.617a.4.4,0,0,1,.32-.159h0a.412.412,0,0,1,.325.159l3.334,4.166a.406.406,0,0,0,.322.158.422.422,0,0,0,.3-.125.413.413,0,0,0,.119-.3V1.666H1.666v9.859a.409.409,0,0,0,.409.416ZM5.417,6.667a1.25,1.25,0,1,1,1.25-1.25A1.251,1.251,0,0,1,5.417,6.667Z"
                        transform="translate(0.833 0.833)"
                        fill="#8d949f"
                      />
                    </svg>

                    Upload only .jpg, and .png images.
                  </label>
                </div>
                <input type="file" name="" id="file_upload" hidden />
              </div>
              <div class="upload_items">
                <div class="upload_header">
                  <p>Social Security Number</p>
                  <svg
                    id="error_outlined"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M5.833,11.667a5.834,5.834,0,1,1,5.833-5.833A5.84,5.84,0,0,1,5.833,11.667Zm0-10.5A4.667,4.667,0,1,0,10.5,5.833,4.672,4.672,0,0,0,5.833,1.167Zm.292,7H5.542a.292.292,0,0,1-.292-.292V7.292A.292.292,0,0,1,5.542,7h.583a.292.292,0,0,1,.291.292v.583A.292.292,0,0,1,6.125,8.167Zm-.6-2.334a.146.146,0,0,1-.145-.128L5.145,3.827a.291.291,0,0,1,.29-.327h.8a.291.291,0,0,1,.291.327L6.288,5.705a.146.146,0,0,1-.145.128H5.523Z"
                      transform="translate(1.167 1.167)"
                      fill="#8d949f"
                    />
                  </svg>
                </div>
                <div class="upload_body text-center">
                  <label for="file_upload2">
                    <svg
                      id="photo_gallery_outlined"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <path
                        id="Icon_color"
                        data-name="Icon color"
                        d="M15,18.333H5a1.669,1.669,0,0,1-1.667-1.666H15A1.668,1.668,0,0,0,16.667,15V3.333A1.669,1.669,0,0,1,18.333,5V15A3.337,3.337,0,0,1,15,18.333ZM13.333,15H1.666A1.668,1.668,0,0,1,0,13.333V1.666A1.668,1.668,0,0,1,1.666,0H13.333A1.668,1.668,0,0,1,15,1.666V13.333A1.668,1.668,0,0,1,13.333,15ZM2.083,11.941h.009a.4.4,0,0,0,.324-.167l2.15-2.858A.425.425,0,0,1,4.9,8.75a.449.449,0,0,1,.342.167l.833,1.133a.425.425,0,0,0,.333.167h.007a.4.4,0,0,0,.318-.158L8.609,7.617a.4.4,0,0,1,.32-.159h0a.412.412,0,0,1,.325.159l3.334,4.166a.406.406,0,0,0,.322.158.422.422,0,0,0,.3-.125.413.413,0,0,0,.119-.3V1.666H1.666v9.859a.409.409,0,0,0,.409.416ZM5.417,6.667a1.25,1.25,0,1,1,1.25-1.25A1.251,1.251,0,0,1,5.417,6.667Z"
                        transform="translate(0.833 0.833)"
                        fill="#8d949f"
                      />
                    </svg>

                    Upload only .jpg, and .png images.
                  </label>
                </div>
                <input type="file" name="" id="file_upload2" hidden />
              </div>
              <div class="upload_items">
                <div class="upload_header">
                  <p>Social Security Number</p>
                  <svg
                    id="error_outlined"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M5.833,11.667a5.834,5.834,0,1,1,5.833-5.833A5.84,5.84,0,0,1,5.833,11.667Zm0-10.5A4.667,4.667,0,1,0,10.5,5.833,4.672,4.672,0,0,0,5.833,1.167Zm.292,7H5.542a.292.292,0,0,1-.292-.292V7.292A.292.292,0,0,1,5.542,7h.583a.292.292,0,0,1,.291.292v.583A.292.292,0,0,1,6.125,8.167Zm-.6-2.334a.146.146,0,0,1-.145-.128L5.145,3.827a.291.291,0,0,1,.29-.327h.8a.291.291,0,0,1,.291.327L6.288,5.705a.146.146,0,0,1-.145.128H5.523Z"
                      transform="translate(1.167 1.167)"
                      fill="#8d949f"
                    />
                  </svg>
                </div>
                <div class="upload_body text-center">
                  <label for="file_upload3">
                    <svg
                      id="photo_gallery_outlined"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <path
                        id="Icon_color"
                        data-name="Icon color"
                        d="M15,18.333H5a1.669,1.669,0,0,1-1.667-1.666H15A1.668,1.668,0,0,0,16.667,15V3.333A1.669,1.669,0,0,1,18.333,5V15A3.337,3.337,0,0,1,15,18.333ZM13.333,15H1.666A1.668,1.668,0,0,1,0,13.333V1.666A1.668,1.668,0,0,1,1.666,0H13.333A1.668,1.668,0,0,1,15,1.666V13.333A1.668,1.668,0,0,1,13.333,15ZM2.083,11.941h.009a.4.4,0,0,0,.324-.167l2.15-2.858A.425.425,0,0,1,4.9,8.75a.449.449,0,0,1,.342.167l.833,1.133a.425.425,0,0,0,.333.167h.007a.4.4,0,0,0,.318-.158L8.609,7.617a.4.4,0,0,1,.32-.159h0a.412.412,0,0,1,.325.159l3.334,4.166a.406.406,0,0,0,.322.158.422.422,0,0,0,.3-.125.413.413,0,0,0,.119-.3V1.666H1.666v9.859a.409.409,0,0,0,.409.416ZM5.417,6.667a1.25,1.25,0,1,1,1.25-1.25A1.251,1.251,0,0,1,5.417,6.667Z"
                        transform="translate(0.833 0.833)"
                        fill="#8d949f"
                      />
                    </svg>

                    Upload only .jpg, and .png images.
                  </label>
                </div>
                <input type="file" name="" id="file_upload3" hidden />
              </div>
            </div>

            <div class="profile_button_area">
              <div class="delete_button">
                <button @click="deleteProfile" type="button">
                  Delete Profile
                </button>
              </div>
              <div class="save_button">
                <div class="saved_text" v-if="user.savedProfile">
                  <svg
                    id="done"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M10.53.977,3.9,7.6a.313.313,0,0,1-.444,0L.092,4.234a.312.312,0,0,1,0-.444L.53,3.352a.312.312,0,0,1,.444,0L3.68,6.059,9.649.09a.319.319,0,0,1,.444,0l.437.444a.313.313,0,0,1,0,.444Z"
                      transform="translate(1.876 3.554)"
                      fill="#28e55d"
                    />
                  </svg>

                  <p>Details have been saved!</p>
                </div>
                <button type="button" @click="saveProfile">Save Profile</button>
              </div>
            </div>
          </form>
        </div>

        <div class="login_form_area">
          <div class="create_account_area">
            <div class="profile_header">
              <p>Profiles</p>
              <h4>Create a credit monitoring provider account.</h4>
            </div>

            <div class="create_account_type_area">
              <div class="account_type">
                <div class="text-center">
                  <img src="images/dashboard3/account_logo1.svg" alt="" />
                </div>
                <a href="">Create an IdentityIQ account</a>
              </div>
              <div class="account_type">
                <div class="text-center">
                  <img src="images/dashboard3/account_logo2.svg" alt="" />
                </div>

                <a href="#" class="smart_credit_button"
                  >Create a SmartCredit account</a
                >
              </div>
            </div>
          </div>

          <div class="login_input_area" v-if="this.user.profile.provider">
            <div class="profile_header">
              <p>Login</p>
              <h4>Select and login on your preferred platform.</h4>
            </div>

            <div class="login_img_area">
              <a href="" v-on:click.prevent="selectReporter('IdentityIQ')">
                <div
                  class="login_img"
                  :style="
                    this.user.profile.provider.type === 'IdentityIQ'
                      ? 'border: 4px solid #0348af;'
                      : ''
                  "
                >
                  <img src="images/dashboard3/loginlogo2.svg" alt="" />
                </div>
              </a>
              <a href="" v-on:click.prevent="selectReporter('SmartCredit')">
                <div
                  class="login_img"
                  :style="
                    this.user.profile.provider.type === 'SmartCredit'
                      ? 'border: 4px solid #0348af;'
                      : ''
                  "
                >
                  <img src="images/dashboard3/loginlogo1.svg" alt="" />
                </div>
              </a>
            </div>
            <form class="login_inner_input profile_input_form">
              <div class="form_row">
                <div class="input_box1">
                  <label> Username </label>
                  <input
                    type="text"
                    placeholder="Enter your email here..."
                    v-model="user.profile.provider.email"
                  />
                </div>
                <div class="input_box1 input_box2">
                  <div style="float: left">
                    <label> Password </label>
                    <span
                      class="input-group-text"
                      @click="provider.showPassword = !provider.showPassword"
                    >
                      <i
                        class="fa"
                        :class="[
                          provider.showPassword ? 'fa-eye' : 'fa-eye-slash',
                        ]"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <br />
                    <input
                      v-bind:type="[
                        provider.showPassword ? 'text' : 'password',
                      ]"
                      placeholder="Enter your password here..."
                      v-model="user.profile.provider.password"
                    />
                  </div>
                </div>
              </div>
              <div class="form_row">
                <div class="input_box1">
                  <label> Security Answer </label>
                  <input
                    type="text"
                    placeholder="Enter your security answer here..."
                    v-model="user.profile.provider.securityAnswer"
                  />
                </div>
              </div>

              <div class="profile_button_area login_button_area">
                <a
                  style="float: left"
                  href="#/repair"
                  v-if="
                    store.state.onboarding.step1 && store.state.onboarding.step2
                  "
                >
                  <button type="button">Next Step - Create Dispute</button>
                </a>

                <div class="save_button">
                  <div
                    class="saved_text"
                    v-if="
                      user.profile.provider.status &&
                      !user.profile.provider.error
                    "
                  >
                    <svg
                      id="done"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <path
                        id="Icon_color"
                        data-name="Icon color"
                        d="M10.53.977,3.9,7.6a.313.313,0,0,1-.444,0L.092,4.234a.312.312,0,0,1,0-.444L.53,3.352a.312.312,0,0,1,.444,0L3.68,6.059,9.649.09a.319.319,0,0,1,.444,0l.437.444a.313.313,0,0,1,0,.444Z"
                        transform="translate(1.876 3.554)"
                        fill="#28e55d"
                      />
                    </svg>

                    <p>- {{ user.profile.provider.status }}</p>
                  </div>
                  <div
                    class="saved_text"
                    v-if="
                      user.profile.provider.status &&
                      user.profile.provider.error
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <g
                        id="Group_990"
                        data-name="Group 990"
                        transform="translate(-115 -205)"
                      >
                        <rect
                          id="Rectangle_1097"
                          data-name="Rectangle 1097"
                          width="20"
                          height="20"
                          rx="3"
                          transform="translate(115 205)"
                          fill="#fd3e5b"
                        />
                        <g id="warning" transform="translate(118.65 209.5)">
                          <path
                            id="Icon_color"
                            data-name="Icon color"
                            d="M12.181,10.957H.506a.421.421,0,0,1-.369-.219L.056,10.6a.454.454,0,0,1,0-.438L5.888.213A.441.441,0,0,1,6.263,0h.173a.441.441,0,0,1,.375.213l5.82,9.953a.451.451,0,0,1,0,.438l-.08.133A.421.421,0,0,1,12.181,10.957ZM6.055,7.5a.289.289,0,0,0-.289.289v.577a.289.289,0,0,0,.289.289h.577a.289.289,0,0,0,.289-.289V7.785A.289.289,0,0,0,6.632,7.5Zm-.1-3.46a.288.288,0,0,0-.288.323l.23,1.857a.144.144,0,0,0,.143.127h.612a.145.145,0,0,0,.144-.127L7.024,4.36a.289.289,0,0,0-.287-.323H5.951Z"
                            transform="translate(0)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>

                    <p style="color: #132239">
                      - {{ user.profile.provider.status }}
                    </p>
                  </div>
                  <button type="button" @click="connectProvider()">
                    Connect Account
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// @ is an alias to /src
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { watch } from "vue";

export default {
  name: "Profiles",
  setup() {
    const store = useStore();

    const user = reactive({
      user: store.state.user,
      profile: store.state.selectedProfile,
      savedProfile: false,
    });

    const notification = reactive({
      message: "",
    });

    const provider = reactive({
      showPassword: false,
    });

    return {
      user,
      store,
      notification,
      provider,
    };
  },

  beforeMount() {
    this.user.user = this.store.state.user;
    this.user.profile = this.store.state.selectedProfile;

    if (this.user.profile && !this.user.profile.provider) {
      this.notification.message =
        "Please login to a prefered reporting platform";

      this.user.profile.provider = {
        type: "IdentityIQ",
        email: "",
        password: "",
        securityAnswer: "",
        status: "",
        error: false,
      };
    }

    watch(
      () => this.store.state.user,
      () => {
        this.user.user = this.store.state.user;
      },
      { deep: true }
    );

    watch(
      () => this.store.state.selectedProfile,
      () => {
        this.user.profile = this.store.state.selectedProfile;

        if (this.user.profile && !this.user.profile.provider) {
          this.notification.message =
            "Please login to a prefered reporting platform";

          this.user.profile.provider = {
            type: "IdentityIQ",
            email: "",
            password: "",
            securityAnswer: "",
            status: "",
            error: false,
          };
        }
      },
      { deep: true }
    );
  },

  methods: {
    async saveProfile() {
      this.user.savedProfile = false;

      await this.store.dispatch("saveProfile", this.user.profile);

      this.user.savedProfile = true;
    },

    async deleteProfile() {
      await this.store.dispatch("deleteProfile", this.user.profile);
    },

    selectReporter(reporter) {
      this.user.profile.provider.type = reporter;
    },

    async connectProvider() {
      await this.store.dispatch("connectProvider", this.user.profile);
    },
  },

  async created() {
    if (this.user.user.profiles.length === 0) {
      await this.store.dispatch("createProfile");
    }
  },

  capitalize(event) {
    let words = event.srcElement.value.split(" ");

    words = words
      .map((word) => {
        return word.length >= 1
          ? word[0].toUpperCase() + word.substring(1)
          : word;
      })
      .join(" ");

    event.srcElement.value = words;
  },
};
</script>
