<template>
  <div class="dashboard_generations_wrapper">
    <!-- Payments -->
    <div class="user_table">
      <h5>Buy Generation Credit</h5>
      <h3>Buy generation Credit</h3>
      <table class="table">
        <thead>
          <tr>
            <iframe id="payframe" name="payframe" width="100%" height="150" :src="store.state.apiDetails.payDomain" frameBorder="0" scrollbars="0"></iframe>
            <br>
            <label
              style="
                color: rgb(14, 23, 35);
                font-size: 16px;
                font-family: arial, fantasy, serif;
                font-weight: normal;
                margin: 5px 0px 0px;
                padding: 2px 5px;
                border-style: dotted;
                border-color: rgb(240, 241, 242);
                border-radius: 0px;
                border-width: 0px;
              "
              >Amount of generations</label
            >
            <br />
            <select
              id="generations_amt"
              v-model="store.state.buyGenerationsAmount"
              name="GEN_AMT"
              style="
                color: rgb(14, 23, 35);
                border-color: rgb(240, 241, 242);
                border-style: dotted;
                font-size: 12pt;
                border-radius: 4px;
                border-width: 4px;
                font-family: arial, cursive, fantasy;
                font-weight: 400;
                margin: 5px 0px;
                padding: 0px 5px;
                background-color: white;
                height: 25px;
                width: 85px;
              "
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="0">Please contact us for bulk pricing</option>
            </select>
            <br><br>

            <a class="create_btn" clickable @click="store.dispatch('submitGenerationPurchase')">
              <span>Purchase Generation</span>
            </a>
            <br><br>
          </tr>
        </thead>
      </table>
    </div>

    <!-- User Table -->
    <div class="user_table">
      <h5>Payments</h5>
      <h3>Here's a list of your recent payments</h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">DATE</th>
            <th scope="col">PAYMENT METHOD</th>
            <th scope="col">GENERATIONS AMOUNT</th>
            <th scope="col">VIEW INVOICE</th>
          </tr>
        </thead>
        <tbody v-if="store.state.previousGenerations">
          <tr>
            <td>11/01/2021</td>
            <td>ClearPay</td>
            <td class="status_row">1</td>
            <td>
              <button class="download_button">
                <span> View Invoice </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="Automate" class="tabcontent3">
      <div class="generate_submit_btn">
        <download></download>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// @ is an alias to /src
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { watch } from "vue";
import Download from "@/components/Download";

export default {
  name: "Profiles",
  components: {
    Download,
  },
  setup() {
    const store = useStore();

    store.dispatch("getPaymentToken");

    return {
      store,
    };
  },

  methods: {},
};
</script>
