import { createStore } from 'vuex'
import axios from 'axios'

import Swal from 'sweetalert2'
import apiDetails from '../../api.json'

const handleRequestError = (commit, error) => {
  console.error(error)

  commit('basic', { key: 'isLoading', value: false })

  Swal.fire({
    icon: 'error',
    title: 'Network Error',
    text: 'A network error occurred, please',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Reload the page'
  }).then((result) => {
    location.reload()
  })

  throw new Error(error)
}

const step1Complete = (state) => {
  if (!state.selectedProfile || !state.selectedProfile.personalDetails) return false
  const keys = Object.keys(state.selectedProfile.personalDetails)
  const values = keys.map(key => state.selectedProfile.personalDetails[key]).filter(value => value)

  return values.length === keys.length
}
const step2Complete = (state) => {
  if (!state.selectedProfile || !state.selectedProfile.provider) return false
  const keys = Object.keys(state.selectedProfile.provider)
  const values = keys.map(key => state.selectedProfile.provider[key]).filter(value => value.length > 0)

  return values.length >= (keys.length - 1)
}
const step3Complete = (state) => {
  if (!state.user) return false

  return state.user.generationKeysAvailable >= 1
}
const step4Complete = (state) => {
  if (state.previousGenerations && state.previousGenerations.length >= 1) return true
  if (state.selectedProfile && state.selectedProfile.hasGeneratedLetter >= 1) return true

  return false
}

export default createStore({
  state: {
    user: {},

    currentAttackMode: 'Automatic',

    selectedProfile: null,
    selectedProfileId: null,
    selectedReport: null,
    selectedReportId: null,

    attackSessionId: null,
    attackSession: null,

    generations: null,
    previousGenerations: null,

    notification: '',

    buyGenerationsAmount: 1,
    paymentInstance: null,

    onboarding: {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    },

    apiDetails,
    payFrameHooked: false,

    isLoading: true,

    selectedProfileOption: {
      id: null,
      label: 'Loading'
    },
    selectedReportOption: {
      id: null,
      label: 'Select Credit Report'
    },

    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    states: [
      { "label": "Alabama", "id": "AL" },
      { "label": "Alaska", "id": "AK" },
      { "label": "Arizona", "id": "AZ" },
      { "label": "Arkansas", "id": "AR" },
      { "label": "California", "id": "CA" },
      { "label": "Colorado", "id": "CO" },
      { "label": "Connecticut", "id": "CT" },
      { "label": "Delaware", "id": "DE" },
      { "label": "Florida", "id": "FL" },
      { "label": "Georgia", "id": "GA" },
      { "label": "Hawaii", "id": "HI" },
      { "label": "Idaho", "id": "ID" },
      { "label": "Illinois", "id": "IL" },
      { "label": "Indiana", "id": "IN" },
      { "label": "Iowa", "id": "IA" },
      { "label": "Kansas", "id": "KS" },
      { "label": "Kentucky", "id": "KY" },
      { "label": "Louisiana", "id": "LA" },
      { "label": "Maine", "id": "ME" },
      { "label": "Maryland", "id": "MD" },
      { "label": "Massachusetts", "id": "MA" },
      { "label": "Michigan", "id": "MI" },
      { "label": "Minnesota", "id": "MN" },
      { "label": "Mississippi", "id": "MS" },
      { "label": "Missouri", "id": "MO" },
      { "label": "Montana", "id": "MT" },
      { "label": "Nebraska", "id": "NB" },
      { "label": "Nevada", "id": "NV" },
      { "label": "New Hampshire", "id": "NH" },
      { "label": "New Jersey", "id": "NJ" },
      { "label": "New Mexico", "id": "NM" },
      { "label": "New York", "id": "NY" },
      { "label": "North Carolina", "id": "NC" },
      { "label": "North Dakota", "id": "ND" },
      { "label": "Ohio", "id": "OH" },
      { "label": "Oklahoma", "id": "OK" },
      { "label": "Oregon", "id": "OR" },
      { "label": "Pennsylvania", "id": "PA" },
      { "label": "Rhode Island", "id": "RI" },
      { "label": "South Carolina", "id": "SC" },
      { "label": "South Dakota", "id": "SD" },
      { "label": "Tennessee", "id": "TN" },
      { "label": "Texas", "id": "TX" },
      { "label": "Utah", "id": "UT" },
      { "label": "Vermont", "id": "VT" },
      { "label": "Virginia", "id": "VA" },
      { "label": "Washington", "id": "WA" },
      { "label": "West Virginia", "id": "WV" },
      { "label": "Wisconsin", "id": "WI" },
      { "label": "WY", "id": "Wyoming" }
    ]
  },
  mutations: {
    basic(state, payload) {
      state[payload.key] = payload.value

      if (payload.key === 'previousGenerations') {
        state.onboarding.step4 = step4Complete(state)
      }
    },
    user(state, payload) {
      state.user = payload.value

      if (!state.selectedProfileId && state.user.profiles.length > 0) {
        state.selectedProfileId = state.user.profiles[0].id
      }

      state.onboarding.step3 = step3Complete(state)
    },
    addProfile(state, payload) {
      state.user.profiles.push(payload.value)

      state.selectedProfileId = payload.value.id
      if (payload.value.provider) state.selectedReportId = payload.value.provider.latestReportId

      if (state.attackSessionId) {
        state.attackSessionId = null
        window._store.dispatch("getAttackSession", state.currentAttackMode)
      }
    },
    deleteProfile(state, payload) {
      state.user.profiles = state.user.profiles.filter(profile => {
        return payload.value.id !== profile.id
      })

      if (state.user.profiles.length > 0) {
        state.selectedProfileId = state.user.profiles[0].id
        state.selectedProfile = null

        window._store.dispatch("getProfile", state.selectedProfileId)
      } else {
        state.selectedProfileId = null
        state.selectedProfile = null

        window._store.dispatch('createProfile')
      }

      if (state.attackSessionId) {
        state.attackSessionId = null
        window._store.dispatch("getAttackSession", state.currentAttackMode)
      }
    },
    updateProfile(state, payload) {
      state.selectedProfile = payload.value
      if (state.selectedProfile.provider) state.selectedReportId = state.selectedProfile.provider.latestReportId

      state.selectedProfileOption.label = state.selectedProfile.name
      state.selectedProfileOption.id = state.selectedProfile.id

      state.onboarding.step1 = step1Complete(state)
      state.onboarding.step2 = step2Complete(state)
      state.onboarding.step4 = step4Complete(state)

      if (state.attackSessionId) {
        state.attackSessionId = null
        window._store.dispatch("getAttackSession", state.currentAttackMode)
      }
    },

    setReport(state, payload) {
      const selectedReport = state.selectedProfile.profileReports.filter(report => report.date === payload.value)

      state.selectedReportId = selectedReport[0].reportId
      state.selectedReport = selectedReport[0]

      if (state.attackSessionId) {
        state.attackSessionId = null
        window._store.dispatch("getAttackSession", state.currentAttackMode)
      }
    }
  },
  actions: {
    async fetchAccount({ commit }) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .get(apiDetails.crud + '/account/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('user', {
              value: r.data
            })
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async createProfile({ commit }) {
      if (!window.$auth.isAuthenticated.value) return

      let profileName = ''

      profileName = await Swal.fire({
        title: 'Enter your profile name',
        input: 'text',
        inputLabel: 'Profile Name',
        showCancelButton: true
      })
      profileName = profileName.value

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .post(apiDetails.crud + '/profile/create', {
          profileName
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            r.data.id = r.data.profileId
            commit('addProfile', {
              value: r.data
            })

            Swal.fire(
              'Profile Created',
              'Your new profile has been created',
              'success'
            )
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async getProfile({ commit }, selectedProfileId) {
      if (!window.$auth.isAuthenticated.value) return
      if (!selectedProfileId) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .get(apiDetails.crud + '/profile/get/' + selectedProfileId, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('updateProfile', {
              key: 'selectedProfile',
              value: r.data
            })
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async deleteProfile({ commit }, profile) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .post(apiDetails.crud + '/profile/delete/' + profile.id,
          {
          }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => {
          commit('basic', { key: 'isLoading', value: false })
          commit('deleteProfile', { value: profile })

          Swal.fire(
            'Profile Deleted',
            'Your profile has been deleted',
            'success'
          )
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async saveProfile({ commit }, profile) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      this.state.onboarding.step1 = step1Complete(this.state)
      this.state.onboarding.step2 = step2Complete(this.state)

      return axios
        .post(apiDetails.api + '/profile/update/' + profile.id,
          {
            name: profile.name,
            personalDetails: profile.personalDetails
          }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => {
          commit('basic', { key: 'isLoading', value: false })

          if (window._store.state.attackSessionId) {
            commit('basic', { key: 'attackSessionId', value: null })
            window._store.dispatch("getAttackSession", window._store.state.currentAttackMode)
          }

          Swal.fire(
            'Profile Saved',
            'Your profile has been saved',
            'success'
          )
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async connectProvider({ commit }, profile) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .post(apiDetails.api + '/profile/update/provider/' + profile.id,
          {

            type: profile.provider.type,
            email: profile.provider.email,
            password: profile.provider.password,
            securityAnswer: profile.provider.securityAnswer

          }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((result) => {
          commit('basic', { key: 'isLoading', value: false })

          if (result.data.ok) {
            profile.provider.error = false;
            profile.provider.status = "Successfully imported account";

            Swal.fire(
              'Success',
              'Credified has imported your credit report',
              'success'
            )
          } else {
            Swal.fire(
              'Error',
              'Credified could not import your credit report. ' + (result.data.errors || '').toString(),
              'success'
            )

            profile.provider.error = true;
            profile.provider.status = result.data.errors;
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async getAttackSession({ commit, state }, type) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .post(apiDetails.crud + '/attack/session/new/' + state.selectedReportId,
          {
            type
          }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('basic', {
              key: 'attackSessionId',
              value: r.data.sessionId
            })
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    async updateAttackSessionOptions({ commit, state }, type) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .post(apiDetails.crud + '/attack/session/update/' + state.attackSessionId,
          {
            attackOptions: state.attackSession.attackOptions,
            destinationAddress: state.attackSession.destinationAddress
          }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => {
          commit('basic', { key: 'isLoading', value: false })
        })
        .catch(handleRequestError.bind(this, commit))
    },

    generateSession({ commit, state }) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      return window.$auth.getTokenSilently().then(token => {
        return axios
          .post(apiDetails.api + '/attack/session/generate/' + state.attackSessionId,
            {}, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
      })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('basic', {
              key: 'generations',
              value: r.data.generations
            })
          }
        })
        .catch(err => {
          handleRequestError(err)
        })
    },

    getSession({ commit, state }, attackSessionId) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      return window.$auth.getTokenSilently().then(token => {
        return axios
          .post(apiDetails.crud + '/attack/session/get/' + attackSessionId,
            {}, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
      })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('basic', {
              key: 'attackSession',
              value: r.data.session
            })
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    getPastGenerations({ commit, state }) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      return window.$auth.getTokenSilently().then(token => {
        return axios
          .get(apiDetails.crud + '/attack/list/' + state.selectedProfileId,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
      })
        .then(r => {
          commit('basic', { key: 'isLoading', value: false })

          if (r && r.data) {
            commit('basic', {
              key: 'previousGenerations',
              value: r.data.previousGenerations
            })
          }
        })
        .catch(handleRequestError.bind(this, commit))
    },

    downloadSession({ commit, state }, sessionId) {
      if (!window.$auth.isAuthenticated.value) return

      return new Promise((resolve, reject) => {
        commit('basic', { key: 'isLoading', value: true })
        return window.$auth.getTokenSilently().then(token => {
          return axios
            .get(apiDetails.crud + '/attack/session/download/' + sessionId,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                },
                responseType: 'blob'
              })
        })
          .then(r => {
            commit('basic', { key: 'isLoading', value: false })

            if (r && r.data) {
              resolve(r.data)
            }
          })
          .catch((error) => {
            handleRequestError.bind(this, commit)(error)
            reject(error)
          })
      })
    },

    async getPaymentToken({ commit, state }, type) {
      if (!window.$auth.isAuthenticated.value) return

      commit('basic', { key: 'isLoading', value: true })
      const token = await window.$auth.getTokenSilently()

      return axios
        .get(apiDetails.crud + '/payments/token/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((r) => {
          commit('basic', { key: 'isLoading', value: false })

          let payFrame = window.frames['payframe']

          if (!state.payFrameHooked) {
            window.addEventListener('message', async (evt) => {
              if (evt.data.messageType === 'processPaymentResponse' && evt.origin === apiDetails.payDomain) {
                commit('basic', { key: 'isLoading', value: true })
                const token = await window.$auth.getTokenSilently()

                return axios
                  .post(apiDetails.crud + '/payments/purchase/',
                    {

                      cardProcessResponse: evt.data.processResponse,
                      buyGenerationsAmount: parseInt(state.buyGenerationsAmount)
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`
                      }
                    })
                  .then((r) => {
                    commit('basic', { key: 'isLoading', value: false })

                    if (r.data.success) {
                      window._store.state.user.generationKeysAvailable++

                      Swal.fire(
                        'Payment Successful',
                        'Thank you, you may now generate your letters',
                        'success'
                      )
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Payment Error',
                        text: 'The payment did not process properly, please try again.',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Reload the page'
                      }).then((result) => {
                        location.reload()
                      })
                    }
                  })
                  .catch(handleRequestError.bind(this, commit))
              }
            })
            state.payFrameHooked = true
          }

          payFrame.postMessage({
            clientKey: r.data.clientKey,
            messageType: 'clientKey'
          }, state.apiDetails.payDomain)
        })
        .catch(handleRequestError.bind(this, commit))
    },


    async submitGenerationPurchase({ commit, state }, type) {
      if (!window.$auth.isAuthenticated.value) return

      let payFrame = window.frames['payframe']

      payFrame.postMessage({
        messageType: 'processPayment'
      }, state.apiDetails.payDomain)
    },

    selectReport({ commit, state }, value) {
      commit('setReport', {
        value: value
      })
    },

    selectProfile({ commit, state }, value) {
      commit('basic', {
        key: 'selectedProfileId',
        value: value
      })
    }
  },
  modules: {
  }
})
