<template>
  <div>
    <!-- Header Section -->
    <header class="header_wrapper">
      <div class="overlay_main"></div>
      <div class="header_container">
        <div class="logo_area">
          <div class="menu_icon">
            <i class="fas fa-bars"></i>
          </div>

          <div class="logo_text">
            <h3>{{ capitalize($route.name) }}</h3>
          </div>
        </div>
        <div class="header_btn_area">
          <a
            class="create_btn"
            :href="
              user.user && user.user.profiles
                ? user.user.profiles.length === 0
                  ? '#/profiles'
                  : '#/repair'
                : ''
            "
          >
            <span>
              <svg
                id="add"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
              >
                <path
                  id="Icon_color"
                  data-name="Icon color"
                  d="M8.167,3.792v.583a.292.292,0,0,1-.292.292H4.667V7.875a.292.292,0,0,1-.292.292H3.792A.292.292,0,0,1,3.5,7.875V4.667H.292A.292.292,0,0,1,0,4.375V3.792A.292.292,0,0,1,.292,3.5H3.5V.292A.292.292,0,0,1,3.792,0h.583a.292.292,0,0,1,.292.292V3.5H7.875A.292.292,0,0,1,8.167,3.792Z"
                  transform="translate(2.917 2.917)"
                  fill="#fff"
                />
              </svg>
            </span>
            <span
              v-if="
                user.user &&
                user.user.profiles &&
                user.user.profiles.length === 0
              "
              >Add a New Profile</span
            >
            <span
              v-if="
                user.user &&
                user.user.profiles &&
                user.user.profiles.length >= 1
              "
              >Create a New Dispute</span
            >
          </a>

          <div style="width: 400px">
            <v-select
              :clearSearchOnSelect="false"
              v-model="store.state.selectedProfileOption"
              :options="
                store.state.user.profiles.map((profile) => {
                  return { label: profile.name, id: profile.id };
                }).concat([{ label: 'Create Profile', id: 'Create Profile' }])
              "
              v-if="
                user.user &&
                user.user.profiles &&
                user.user.profiles.length >= 1
              "
              v-tippy="{
                content: 'Select / Add a New Profile',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
            </v-select>
          </div>

          <div class="language_switcher">
            <div class="dropdown">
              <button class="btn" type="button" id="languageSwitcherBtn">
                <img src="images/dashboard/usflag.png" alt="" />
                English
                <span>
                  <svg
                    id="chevron_down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M4.479,4.472a.5.5,0,0,1-.353.147H3.872a.513.513,0,0,1-.353-.147L.1,1.045A.333.333,0,0,1,.1.572L.572.1a.327.327,0,0,1,.467,0L4,3.065,6.959.1a.333.333,0,0,1,.473,0L7.9.572a.333.333,0,0,1,0,.473Z"
                      transform="translate(4.001 6.048)"
                      fill="#89909c"
                    />
                  </svg>
                </span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="images/dashboard/arabic_flag.png" alt="" />
                    Arabic</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="images/dashboard/bangladesh.png" alt="" />
                    Bangla</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Sidebar Section -->
    <section class="sidebar_wrapper">
      <div class="tab_content_wrapper">
        <div class="tab sidebar_tab_main">
          <div class="tab_button_area">
            <div class="img_logo">
              <a href="#">
                <img src="images/header/logo.png" alt="" />
              </a>
            </div>
            <a
              href="#/"
              :class="
                $route.name === 'home'
                  ? 'tablinks first_tab_btn active'
                  : 'tablinks first_tab_btn'
              "
              v-tippy="{
                content: 'Dashboard',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
              <svg
                id="dashboard"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
              >
                <path
                  id="Icon_color"
                  data-name="Icon color"
                  d="M17.708,18.75h-6.25a1.043,1.043,0,0,1-1.042-1.042V13.541A1.043,1.043,0,0,1,11.458,12.5h6.25a1.043,1.043,0,0,1,1.042,1.042v4.167A1.043,1.043,0,0,1,17.708,18.75Zm-10.417,0H1.042A1.043,1.043,0,0,1,0,17.708V9.375A1.043,1.043,0,0,1,1.042,8.333h6.25A1.043,1.043,0,0,1,8.333,9.375v8.333A1.043,1.043,0,0,1,7.292,18.75Zm10.417-8.333h-6.25a1.043,1.043,0,0,1-1.042-1.042V1.042A1.043,1.043,0,0,1,11.458,0h6.25A1.043,1.043,0,0,1,18.75,1.042V9.375A1.043,1.043,0,0,1,17.708,10.417ZM7.292,6.25H1.042A1.043,1.043,0,0,1,0,5.209V1.042A1.043,1.043,0,0,1,1.042,0h6.25A1.043,1.043,0,0,1,8.333,1.042V5.209A1.043,1.043,0,0,1,7.292,6.25Z"
                  transform="translate(3.125 3.125)"
                  fill="#132239"
                />
              </svg>
            </a>
            <a
              href="#/repair"
              :class="$route.name === 'repair' ? 'tablinks active' : 'tablinks'"
              v-tippy="{
                content: 'Repair',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.712"
                height="20.7"
                viewBox="0 0 20.712 20.7"
              >
                <g
                  id="Group_1189"
                  data-name="Group 1189"
                  transform="translate(-22.137 -157.244)"
                >
                  <g id="chart_donut_2" transform="translate(22.137 157.244)">
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M10.418,20.7h0A10.416,10.416,0,0,1,8.717.006a.5.5,0,0,1,.417.125.482.482,0,0,1,.187.4V2.61a.523.523,0,0,1-.417.51,7.292,7.292,0,0,0,1.458,14.428h.018a7.17,7.17,0,0,0,3.992-1.209.51.51,0,0,1,.656.062l1.511,1.563a.519.519,0,0,1,.146.406.556.556,0,0,1-.209.385A10.359,10.359,0,0,1,10.418,20.7Zm8.016-4.119a.517.517,0,0,1-.363-.148l-1.51-1.51a.522.522,0,0,1-.063-.656,6.876,6.876,0,0,0,1.042-2.552.523.523,0,0,1,.51-.417h2.083a.478.478,0,0,1,.447.187.5.5,0,0,1,.125.417,10.651,10.651,0,0,1-1.843,4.459.532.532,0,0,1-.386.219Z"
                      transform="translate(0 0)"
                      fill="#132239"
                    />
                  </g>
                  <g id="wrench" transform="translate(28.85 159.25)">
                    <path
                      id="Icon_color-2"
                      data-name="Icon color"
                      d="M12,2.457v.214A2.669,2.669,0,0,1,9.333,5.342a2.59,2.59,0,0,1-1.04-.214L1.627,11.806A.666.666,0,0,1,1.16,12H1.02a.666.666,0,0,1-.467-.194l-.36-.361A.668.668,0,0,1,0,10.978v-.12a.668.668,0,0,1,.193-.467L6.86,3.713a2.6,2.6,0,0,1-.193-1.042A2.669,2.669,0,0,1,9.333,0h.213a.34.34,0,0,1,.24.1L9.9.214a.334.334,0,0,1,0,.474L8.86,1.73a.334.334,0,0,0,0,.467l.947.948a.34.34,0,0,0,.467,0L11.333,2.1a.333.333,0,0,1,.473,0l.113.114A.341.341,0,0,1,12,2.457Z"
                      fill="#132239"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="#/profiles"
              :class="
                $route.name === 'profiles' ? 'tablinks active' : 'tablinks'
              "
              v-tippy="{
                content: 'Client Profiles',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
              <svg
                id="group_senior"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
              >
                <path
                  id="Icon_color"
                  data-name="Icon color"
                  d="M19.795,14.584h-3.3a3.142,3.142,0,0,0-.157-2.437L14.95,9.375h1.99a3.109,3.109,0,0,1,2.792,1.718l.99,1.979a1.034,1.034,0,0,1-.042,1.014A1.051,1.051,0,0,1,19.795,14.584Zm-6.252,0H1.039a1.042,1.042,0,0,1-.927-1.511l1.5-3.01a3.109,3.109,0,0,1,2.8-1.729h5.766a3.107,3.107,0,0,1,2.8,1.729l1.5,3.01a1.043,1.043,0,0,1-.928,1.511Zm1.564-7.292a2.6,2.6,0,1,1,2.6-2.6A2.608,2.608,0,0,1,15.107,7.292ZM7.292,6.25a3.125,3.125,0,1,1,3.126-3.126A3.13,3.13,0,0,1,7.292,6.25Z"
                  transform="translate(2.086 5.208)"
                  fill="#132239"
                />
              </svg>
            </a>
            <a
              href="#/generations"
              :class="
                $route.name === 'generations' ? 'tablinks active' : 'tablinks'
              "
              v-tippy="{
                content: 'Generations',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.712"
                height="20.7"
                viewBox="0 0 20.712 20.7"
              >
                <g
                  id="Group_1189"
                  data-name="Group 1189"
                  transform="translate(-22.137 -157.244)"
                >
                  <g id="chart_donut_2" transform="translate(22.137 157.244)">
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M10.418,20.7h0A10.416,10.416,0,0,1,8.717.006a.5.5,0,0,1,.417.125.482.482,0,0,1,.187.4V2.61a.523.523,0,0,1-.417.51,7.292,7.292,0,0,0,1.458,14.428h.018a7.17,7.17,0,0,0,3.992-1.209.51.51,0,0,1,.656.062l1.511,1.563a.519.519,0,0,1,.146.406.556.556,0,0,1-.209.385A10.359,10.359,0,0,1,10.418,20.7Zm8.016-4.119a.517.517,0,0,1-.363-.148l-1.51-1.51a.522.522,0,0,1-.063-.656,6.876,6.876,0,0,0,1.042-2.552.523.523,0,0,1,.51-.417h2.083a.478.478,0,0,1,.447.187.5.5,0,0,1,.125.417,10.651,10.651,0,0,1-1.843,4.459.532.532,0,0,1-.386.219Z"
                      transform="translate(0 0)"
                      fill="#132239"
                    />
                  </g>
                  <g id="wrench" transform="translate(28.85 159.25)">
                    <path
                      id="Icon_color-2"
                      data-name="Icon color"
                      d="M12,2.457v.214A2.669,2.669,0,0,1,9.333,5.342a2.59,2.59,0,0,1-1.04-.214L1.627,11.806A.666.666,0,0,1,1.16,12H1.02a.666.666,0,0,1-.467-.194l-.36-.361A.668.668,0,0,1,0,10.978v-.12a.668.668,0,0,1,.193-.467L6.86,3.713a2.6,2.6,0,0,1-.193-1.042A2.669,2.669,0,0,1,9.333,0h.213a.34.34,0,0,1,.24.1L9.9.214a.334.334,0,0,1,0,.474L8.86,1.73a.334.334,0,0,0,0,.467l.947.948a.34.34,0,0,0,.467,0L11.333,2.1a.333.333,0,0,1,.473,0l.113.114A.341.341,0,0,1,12,2.457Z"
                      fill="#132239"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="#/payments"
              :class="
                $route.name === 'payments' ? 'tablinks active' : 'tablinks'
              "
              v-tippy="{
                content: 'Payments',
                appendTo: 'parent',
                theme: 'tomato',
                arrow: false,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.712"
                height="20.7"
                viewBox="0 0 20.712 20.7"
              >
                <g
                  id="Group_1189"
                  data-name="Group 1189"
                  transform="translate(-22.137 -157.244)"
                >
                  <g id="chart_donut_2" transform="translate(22.137 157.244)">
                    <path
                      id="Icon_color"
                      data-name="Icon color"
                      d="M10.418,20.7h0A10.416,10.416,0,0,1,8.717.006a.5.5,0,0,1,.417.125.482.482,0,0,1,.187.4V2.61a.523.523,0,0,1-.417.51,7.292,7.292,0,0,0,1.458,14.428h.018a7.17,7.17,0,0,0,3.992-1.209.51.51,0,0,1,.656.062l1.511,1.563a.519.519,0,0,1,.146.406.556.556,0,0,1-.209.385A10.359,10.359,0,0,1,10.418,20.7Zm8.016-4.119a.517.517,0,0,1-.363-.148l-1.51-1.51a.522.522,0,0,1-.063-.656,6.876,6.876,0,0,0,1.042-2.552.523.523,0,0,1,.51-.417h2.083a.478.478,0,0,1,.447.187.5.5,0,0,1,.125.417,10.651,10.651,0,0,1-1.843,4.459.532.532,0,0,1-.386.219Z"
                      transform="translate(0 0)"
                      fill="#132239"
                    />
                  </g>
                  <g id="wrench" transform="translate(28.85 159.25)">
                    <path
                      id="Icon_color-2"
                      data-name="Icon color"
                      d="M12,2.457v.214A2.669,2.669,0,0,1,9.333,5.342a2.59,2.59,0,0,1-1.04-.214L1.627,11.806A.666.666,0,0,1,1.16,12H1.02a.666.666,0,0,1-.467-.194l-.36-.361A.668.668,0,0,1,0,10.978v-.12a.668.668,0,0,1,.193-.467L6.86,3.713a2.6,2.6,0,0,1-.193-1.042A2.669,2.669,0,0,1,9.333,0h.213a.34.34,0,0,1,.24.1L9.9.214a.334.334,0,0,1,0,.474L8.86,1.73a.334.334,0,0,0,0,.467l.947.948a.34.34,0,0,0,.467,0L11.333,2.1a.333.333,0,0,1,.473,0l.113.114A.341.341,0,0,1,12,2.457Z"
                      fill="#132239"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>

          <div class="user_img" v-if="$auth.isAuthenticated.value">
            <img :src="$auth.user.value.picture" alt="" />
          </div>
        </div>

        <div class="tab_content_area">
          <loading
            :active="store.state.isLoading"
            is-full-page="true"
            loader="bars"
          >
          </loading>
          <router-view v-if="user.user.profiles" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "vue";

export default {
  name: "Home",
  components: {
    Loading,
  },
  setup() {
    const store = useStore();

    const user = reactive({
      user: {},
      profile: {},
      selectedProfileId: null,
    });

    store.dispatch("fetchAccount");

    watch(
      () => store.state.user,
      () => {
        user.user = store.state.user;
      },
      { deep: true }
    );

    watch(
      () => store.state.selectedProfile,
      () => {
        user.profile = store.state.selectedProfile;
      },
      { deep: true }
    );

    watch(
      () => store.state.selectedProfileOption,
      () => {
        if (!store.state.selectedProfileOption) {
          return (store.state.selectedProfileOption = {
            id: store.state.selectedProfileId,
            name: "Select a user",
          });
        }

        if (
          store.state.selectedProfileId === store.state.selectedProfileOption.id
        ) {
          return;
        }

        if (store.state.selectedProfileOption.id === 'Create Profile') {
          window._store.dispatch('createProfile')
          return
        }

        store.state.selectedProfileId = store.state.selectedProfileOption.id;
      },
      { deep: true }
    );

    watch(
      () => store.state.selectedProfileId,
      () => {
        user.selectedProfileId = store.state.selectedProfileId;

        store.dispatch("getProfile", store.state.selectedProfileId);
      },
      { deep: true }
    );

    watch(
      () => store.state.selectedReportOption,
      () => {
        if (!store.state.selectedReportOption) {
          return (store.state.selectedReportOption = {
            id: store.state.selectedReportId,
            label: "Select Credit Report",
          });
        }

        if (
          store.state.selectedReportId ===
          store.state.selectedReportOption.id
        ) {
          return;
        }

        store.state.selectedReportId = store.state.selectedReportOption.id;
      },
      { deep: true }
    );

    watch(
      () => store.state.attackSessionId,
      () => {
        if (store.state.attackSessionId)
          store.dispatch("getSession", store.state.attackSessionId);
      },
      { deep: true }
    );
    return {
      user,
      store,
    };
  },

  methods: {
    capitalize: (str) => {
      return (str && str.length) >= 1
        ? str.charAt(0).toUpperCase() + str.slice(1)
        : "";
    },
  },
};
</script>

<style scoped>
* >>> .tippy-box[data-theme~="tomato"] {
  background-color: rgba(35, 37, 39, 0.753);
  color: white;

  padding: 5px;
  border-radius: 5px;
}
</style>
