import { createApp } from 'vue'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { plugin as VueTippy } from 'vue-tippy'
import vSelect from 'vue-select'
import './select/select.css';

import App from './App.vue'
import router from './router'
import store from './store'

import authConfig from '../auth_config.json'
import { setupAuth } from './auth'

authConfig.redirect_uri = window.location.origin + '/#/callback'

import createAuth0Client from '@auth0/auth0-spa-js'
if (!Object.keys(localStorage).find(a => a.includes('auth0')) && !window.location.search) {
  createAuth0Client(authConfig).then(client => client.loginWithRedirect('/'))
}

/* Default title tag */
const defaultDocumentTitle = 'credified.com'

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

const app = createApp(App)

/* Sentry.init({
  app,
  dsn: authConfig.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['app.credified.com.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
}) */

app.component('v-select', vSelect)

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
    defaultProps: { placement: 'right' }
  }
)

window._store = store
app.use(store).use(router)


function callbackRedirect(appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  )
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
})
