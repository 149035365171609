
import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/views/Home'
import RepairAutomatic from '@/views/RepairAutomatic'
import Profiles from '@/views/Profiles'
import Generations from '@/views/Generations'
import Payments from '@/views/Payments'
import Callback from '@/views/Callback'
import Logout from '@/views/Logout'

import { routeGuard } from '@/auth'

const routes = [
  {
    meta: {
      title: 'Home'
    },
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: routeGuard
  },
  {
    meta: {
      title: 'Repair'
    },
    path: '/repair',
    name: 'repair',
    component: RepairAutomatic,
    beforeEnter: routeGuard
  },
  {
    meta: {
      title: 'Profiles'
    },
    path: '/profiles',
    name: 'profiles',
    component: Profiles,
    beforeEnter: routeGuard
  },
  {
    meta: {
      title: 'Generations'
    },
    path: '/generations',
    name: 'generations',
    component: Generations,
    beforeEnter: routeGuard
  },
  {
    meta: {
      title: 'Payments'
    },
    path: '/payments',
    name: 'payments',
    component: Payments,
    beforeEnter: routeGuard
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: routeGuard
  }
]

const router = createRouter({
  history: createWebHashHistory('/'),
  routes
})

export default router
