<template>
  <div>

  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, reactive } from "vue"
import { useStore } from "vuex"

export default {
  name: "Logout",
  setup() {
    const store = useStore()

    window.$auth.logout({
      returnTo: 'https://credified.com'
    })

    return {}
  }
}
</script>
