<template>
  <div class="dashboard_generations_wrapper">
    <!-- User Table -->
    <div class="user_table">
      <h5>Generations</h5>
      <h3>Here's a list of your recent generations</h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">REPORT</th>
            <th scope="col">DATE GENERATED</th>
            <th scope="col">BUREAU</th>
            <th scope="col">DOWNLOAD</th>
          </tr>
        </thead>
        <tbody v-if="store.state.previousGenerations">
          <tr
            v-for="(generation, index) in store.state.previousGenerations"
            v-bind:key="index"
          >
            <td>{{ generation.reportId }}</td>
            <td>{{ generation.date }}</td>
            <td class="status_row">
              {{ generation.destinationAddress }}
            </td>
            <td>
              <button
                class="download_button"
                v-on:click="this.store.state.attackSessionId = generation.id; store.state.generations = generation.generations"
              >
                <span>
                  Download
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="Automate" class="tabcontent3">
      <div class="generate_submit_btn">
        <download></download>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// @ is an alias to /src
import { ref, onMounted, reactive } from "vue"
import { useStore } from "vuex"
import { watch } from "vue"
import Download from "@/components/Download"

export default {
  name: "Profiles",
  components: {
    Download,
  },
  setup() {
    const store = useStore()

    store.dispatch("getPastGenerations")

    watch(
      () => store.state.selectedProfile,
      () => {
        store.dispatch("getPastGenerations")
      },
      { deep: true }
    )

    return {
      store,
    }
  },

  methods: {},
}
</script>
