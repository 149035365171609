<template>
  <div>
    <!-- Dashboard 2 Section -->
    <section id="dashboard2Tab" class="tabcontent">
      <div class="dashboard2_wrapper">
        <div
          class="dashboard2_left_area"
          v-if="store.state.user?.generationKeysAvailable > 0"
        >
          <div class="tab2">
            <button
              class="tablinks2"
              onclick="tab2(event, 'generate')"
              id="defaultOpen2"
            >
              Generate
            </button>
          </div>
          <div class="generate_left_wrapper">
            <div id="generate" class="tabcontent2">
              <div class="generate_area">
                <div class="generate_title">
                  <div
                    class="generate_select_area"
                    v-if="
                      store.state.selectedProfile &&
                      store.state.selectedProfile.profileReports
                    "
                  >
                    <div style="width: 400px">
                      <v-select
                        :clearSearchOnSelect="false"
                        v-model="store.state.selectedProfileOption"
                        :options="
                          store.state.user.profiles.map((profile) => {
                            return { label: profile.name, id: profile.id };
                          }).concat([{ label: 'Create Profile', id: 'Create Profile' }])
                        "
                      >
                      </v-select>
                    </div>
                    <div style="width: 400px">
                      <v-select
                        :clearSearchOnSelect="false"
                        v-model="store.state.selectedReportOption"
                        :options="
                          store.state.selectedProfile.profileReports.map((report) => {
                            return { label: `Credit Report - ${report.date}`, id: report.date };
                          })
                        "
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="generate_inner_tab_list">
                    <a class="tablinks3 active3" disabled>Automate</a>
                  </div>
                </div>
              </div>

              <div
                id="Automate"
                class="tabcontent3"
                v-if="store.state.attackSession"
              >
                <!-- Select Area -->
                <div class="select_check_items_title">
                  <p>PLEASE SELECT ONE OR MORE THAT APPLIES</p>
                </div>
                <div class="check_select_items_list">
                  <ul class="list_left_area">
                    <li>
                      <label class="checkbox_area">
                        <input
                          v-model="
                            store.state.attackSession.attackOptions.Lates
                          "
                          type="checkbox"
                        />
                        <span class="checkmark"></span>
                        <span class="list_text"
                          >Attack Lates (Only Late Accounts)</span
                        >
                      </label>
                    </li>
                  </ul>
                </div>

                <div class="generate_submit_btn">
                  <button
                    type="submit"
                    id="generatePopupButton2"
                    @click="generate()"
                  >
                    Generate
                  </button>
                  <download></download>
                </div>

                <div class="important_notice_ara" v-if="store.state?.user">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g
                      id="Group_990"
                      data-name="Group 990"
                      transform="translate(-115 -205)"
                    >
                      <rect
                        id="Rectangle_1097"
                        data-name="Rectangle 1097"
                        width="20"
                        height="20"
                        rx="3"
                        transform="translate(115 205)"
                        fill="#fd3e5b"
                      />
                      <g id="warning" transform="translate(118.65 209.5)">
                        <path
                          id="Icon_color"
                          data-name="Icon color"
                          d="M12.181,10.957H.506a.421.421,0,0,1-.369-.219L.056,10.6a.454.454,0,0,1,0-.438L5.888.213A.441.441,0,0,1,6.263,0h.173a.441.441,0,0,1,.375.213l5.82,9.953a.451.451,0,0,1,0,.438l-.08.133A.421.421,0,0,1,12.181,10.957ZM6.055,7.5a.289.289,0,0,0-.289.289v.577a.289.289,0,0,0,.289.289h.577a.289.289,0,0,0,.289-.289V7.785A.289.289,0,0,0,6.632,7.5Zm-.1-3.46a.288.288,0,0,0-.288.323l.23,1.857a.144.144,0,0,0,.143.127h.612a.145.145,0,0,0,.144-.127L7.024,4.36a.289.289,0,0,0-.287-.323H5.951Z"
                          transform="translate(0)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <p>
                    <span>IMPORTANT:</span> You only have
                    {{ store.state.user.generationKeysAvailable }} more
                    generation remaining.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="dashboard2_left_area"
          v-if="store.state.user?.generationKeysAvailable === 0"
        >
          <div class="tab2">
            <button
              class="tablinks2"
              onclick="tab2(event, 'generate')"
              id="defaultOpen2"
            >
              No Generations Available
            </button>
          </div>
          <div class="generate_left_wrapper">
            <div id="generate" class="tabcontent2">
              <div
                id="Automate"
                class="tabcontent3"
                v-if="store.state.attackSession"
              >
                <div class="important_notice_ara" v-if="store.state?.user">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g
                      id="Group_990"
                      data-name="Group 990"
                      transform="translate(-115 -205)"
                    >
                      <rect
                        id="Rectangle_1097"
                        data-name="Rectangle 1097"
                        width="20"
                        height="20"
                        rx="3"
                        transform="translate(115 205)"
                        fill="#fd3e5b"
                      />
                      <g id="warning" transform="translate(118.65 209.5)">
                        <path
                          id="Icon_color"
                          data-name="Icon color"
                          d="M12.181,10.957H.506a.421.421,0,0,1-.369-.219L.056,10.6a.454.454,0,0,1,0-.438L5.888.213A.441.441,0,0,1,6.263,0h.173a.441.441,0,0,1,.375.213l5.82,9.953a.451.451,0,0,1,0,.438l-.08.133A.421.421,0,0,1,12.181,10.957ZM6.055,7.5a.289.289,0,0,0-.289.289v.577a.289.289,0,0,0,.289.289h.577a.289.289,0,0,0,.289-.289V7.785A.289.289,0,0,0,6.632,7.5Zm-.1-3.46a.288.288,0,0,0-.288.323l.23,1.857a.144.144,0,0,0,.143.127h.612a.145.145,0,0,0,.144-.127L7.024,4.36a.289.289,0,0,0-.287-.323H5.951Z"
                          transform="translate(0)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <p>
                    <span>IMPORTANT:</span> You only have
                    {{ store.state.user.generationKeysAvailable }} more
                    generation remaining.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard2_right_area" v-if="store.state.attackSession">
          <div class="sidebar_checkbox_area">
            <div class="sidebar_title">
              <h6>Destinations</h6>
              <h3>Check the available destinations.</h3>
            </div>
            <div class="sidebar_inner_title">
              <p>CONTENT DESTINATIONS</p>
            </div>
          </div>

          <ul class="sidebar_list_area">
            <li
              v-for="(destination, index) in store.state.attackSession
                .destinationAddress"
              v-bind:key="index"
            >
              <label class="checkbox_area">
                <input type="checkbox" v-model="destination.selected" />
                <span class="checkmark"></span>
                <span class="list_text">{{ destination.value }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @ is an alias to /src
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Download from "@/components/Download";

import Swal from "sweetalert2";

export default {
  name: "Repair",
  components: {
    Download,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },

  async mounted() {
    this.store.state.currentAttackMode = "Automatic";
    if (this.store.state.attackSessionId) return;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    await new Promise((resolve) => {
      let timer = setInterval(() => {
        if (that.store.state.isLoading) return;

        if (!that.store.state.onboarding.step1) {
          clearInterval(timer);

          Swal.fire({
            title: "Profile infomation not added",
            text: "In order to generate letters you need to add infomation to your profile",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Go to profile page",
          }).then((result) => {
            if (result.isConfirmed) {
              location.hash = "#/profiles";
            }
          });

          return;
        }

        if (!that.store.state.user?.generationKeysAvailable === 0) {
          clearInterval(timer);

          Swal.fire({
            title: "No Generations Available",
            text: "In order to generate letters you need to purchase letter generations",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Go to purchase page",
          }).then((result) => {
            if (result.isConfirmed) {
              location.hash = "#/payments";
            }
          });

          return;
        }

        if (!that.store.state.selectedProfile?.provider?.latestReportId) {
          clearInterval(timer);

          Swal.fire({
            title: "Profile does not have a credit report",
            text: "You need to add a credit report provider and connect it in order to generate a letter",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Go to profile page",
          }).then((result) => {
            if (result.isConfirmed) {
              location.hash = "#/profiles";
            }
          });

          return;
        }

        if (!that.store.state.selectedReportId) return;

        this.store.dispatch(
          "getAttackSession",
          this.store.state.currentAttackMode
        );

        clearInterval(timer);
        resolve();
      }, 250);
    });
  },

  methods: {
    async generate() {
      if (!this.store.state.attackSessionId) {
        await this.store.dispatch(
          "getAttackSession",
          this.store.state.currentAttackMode
        );
      }

      await this.store.dispatch("updateAttackSessionOptions");
      await this.store.dispatch("generateSession");
    },
  },
};
</script>
