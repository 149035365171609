<template>
  <!-- Popup Area -->
  <div
    class="generate_popup_area popup_active"
    id="generatePopup2"
    v-if="store.state.generations && store.state.generations.length > 0"
  >
    <div class="popup_header">
      <div class="popup_title">
        <h6>Letter Preview</h6>
        <h3>Here is a generated letter preview.</h3>
      </div>
      <div class="popup_inner_header">
        <img src="images/dashboard2/warningPopup.png" alt="" />
        <h5>
          <span>IMPORTANT:</span> Make sure to download and email your letters.
          DON'T FORGET!
        </h5>
      </div>
    </div>
    <div class="popup_body text-center">
      <img src="images/dashboard/generation.png" alt="" />
    </div>
    <div class="popup_footer">
      <div class="close_button_area">
        <button type="button" id="popupCloseButton2" @click="closeGenerations">
          Close Window
        </button>
      </div>
      <div class="download_button_area">
        <button type="button" @click="downloadAllPdfs()">Download PDF</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { reactive } from "vue";
import { useStore } from "vuex";

import Swal from "sweetalert2";

export default {
  name: "Download",
  setup(props) {
    const store = useStore();

    return {
      store,
    };
  },

  methods: {
    async closeGenerations() {
      this.store.state.generations = [];

      this.store.state.attackSessionId = null;
      this.store.state.attackSession = null;

      await this.store.dispatch("getAttackSession", "Automatic");
    },

    async downloadAllPdfs() {
      const data = await this.store.dispatch(
        "downloadSession",
        this.store.state.attackSessionId
      );

      const blob = new Blob([data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${Date.now()}.zip`;
      link.click();

      await Swal.fire(
        "Generations Downloaded",
        "Your letters has been downloaded",
        "success"
      );
      this.store.state.generations = [];
    },
  },
};
</script>
